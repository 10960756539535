@import "statics";

#testimonial{
    width: 100%;
    min-height: 10vh;
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
    padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 3vw + 2rem, 5rem);

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        margin-bottom: 2rem;
        text-align: center;
    }

    >.cards{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 4fr));
        gap: 2rem;
        justify-content: center;

        >.card{
            width: 100%;
            background-color: $color_white_d;
            padding: clamp(1rem, 1vw + 0.8rem, 2rem);
            border-radius: 10px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            display: flex;
            flex-direction: column;
            align-items: center;

            >.stars{
                padding: 0.5rem 0;
                color: $color_yellow_d;
            }

            >svg{
                color: $color_red_d;
                font-size: larger;
            }

            >p{
                font-weight: 400;
                font-size: clamp(0.4rem, 1vw + 0.4rem, 0.8rem);
                font-family: $text4;
                color: $color_gray_d;
                letter-spacing: 1px;
            }
        }
        >#i1{
            animation-delay: 0.2;
        }
        >#i2{
            animation-delay: 0.4;
        }
        >#i3{
            animation-delay: 0.6;
        }
        >#i4{
            animation-delay: 0.8;
        }
    }
}