@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:wght@400;700&family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "statics";
@import "media";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $text2;
    scroll-behavior: smooth;
    text-decoration: none;
}

body{
    background: linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF);
}

#whatsapp{
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 99999999;
    border-radius: 60%;
    overflow: hidden;
    height: 2.6rem;
    width: 2.6rem;
    background-color: $color_white;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 25px, rgba(0, 0, 0, 0.5) 0px 5px 10px;

    >a{
        color: $color_whatsapp;

        >svg{
            font-size: 3rem;
            margin: -3px 0 0 -3px;
        }
    }
}

#contact{
    position: fixed;
    bottom: 5%;
    right: 10%;
    z-index: 99999999;
    border-radius: 60%;
    overflow: hidden;
    height: 2.6rem;
    width: 2.6rem;
    background-color: $color_yellow_dd;
    color: $color_white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 25px, rgba(0, 0, 0, 0.5) 0px 5px 10px;


    >span{

    svg{
        font-size: 2.2rem;
        margin: 3px 0 0 3px;
    }}
}

#contactmodal{
    position: fixed;
    bottom: 6%;
    right: 11%;
    border-radius: 10px;
    z-index: 99999999;
    min-height: 5rem;
    min-width: 10rem;
    background-color: $color_white;
    padding: 1.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    
    >a{
        display: flex;
        color: $color_gray_d;

        &:first-of-type{
            margin-bottom: .5rem;
        }
        >svg{
            font-size: 1.3rem;
        }
        >p{
            margin-left: .3rem;
            font-weight: 400;
            font-size: 1rem;
            font-family: $text4;
        }
    }
}