@import "statics";

#roomsandsuits{
    width: 100%;
    min-height: 50vh;
    padding: 0 clamp(1.5rem, 3vw + 2rem, 5rem);

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        text-align: center;
        padding: clamp(1rem, 1vw + 0.8rem, 2rem);
    }

    >main{

        >.card{
            display: flex;
            margin-bottom: clamp(1.5rem, 1vw + 1.2rem, 3rem);

            &:nth-of-type(even){
                flex-direction: row-reverse;
                text-align: end;
            }

            >img{
                width: 45%;
                height: 25vw;
                object-fit: cover;
            }

            >.roomcontent{
                width: 55%;
                padding: 0 clamp(1rem, 1vw + 0.8rem, 2rem) .8rem clamp(1rem, 1vw + 0.8rem, 2rem);
                height: fit-content;
                margin: auto 0;

                >h4{
                    font-weight: 600;
                    font-family: $text3;
                    font-size: clamp(1rem, 1vw + 0.8rem, 2rem);
                    margin-bottom: .5rem;
                }

                >p{
                    font-weight: 400;
                    font-size: 1rem;
                    font-family: $text4;
                    color: $color_gray_d;
                    letter-spacing: 1.3px;
                }

                >.occupancy{
                    margin-bottom: 1.5rem;
                    font-weight: bold;
                }

                >a{
                    background-color: $color_yellow_dd;
                    color: $color_white;
                    padding: .5rem 1.5rem;
                    transition: all 0.5s;

                    &:hover{
                        background-color: $color_white;
                        color: $color_yellow_dd;
                    }
                }
            }
        }
    }
}