@import "statics";

@media screen and (max-width: 768px) {
    
    .navBar{
        position: relative;
        min-height: 10vh !important;
    
        // >img{
        //     position: absolute;
        //     left: 10% !important;
        //     transform: translateX(-10%) !important;
        // }
    
        >div{
            display: none !important;
        }

        >aside{
            position: absolute;
            right: 10%;
            top: 30%;
            font-size: larger;
            p{
                display: none !important;
            }
        }
    }

    .navbaraside{
        width: 60% !important;

        >div{
            transform: translateX(0) !important;
        }
    }

    #contact{
        right: 15% !important;
    }

    #contactmodal{
        right: 16% !important;
    }

    .hero{
        height: 70vh !important;

        >section{
        >.carousel{
            height: 70vh !important;
            .slide{ img{
                height: 70vh !important;
            }}
        }

        >main{
            height: 70vh !important;
        }
    }
    }

    #aboutus{ 
        padding: 2rem 1rem !important;

        >.aboutImages{
            width: 47% !important;

            >p .img2{
                margin-right: -2rem !important;
            }
        }
        >.aboutContent{
            padding: 3rem 2rem !important;

            >p, ul>li{
                font-size: 0.8rem !important;
            }
        }
    }

    #roomsandsuits main{
        >.card{
            flex-direction: column;
            text-align: center;

            &:nth-of-type(even){
                flex-direction: column !important;
                text-align: center !important;
            }

            >img{
                height: 30vh !important;
                width: 100% !important;
            }

            >.roomcontent{
                width: 100% !important;
                padding: clamp(1rem, 1vw + 0.8rem, 2rem) 0 !important;

                >p{
                    text-align: justify;
                }
            }
        }
    }

    #gallery{
        .imgdiv{
            height: 200px !important;
            .imgimg{
                height: 200px !important;
            }
        } 
    }

    #services{
        >main{
            flex-direction: column;
        }
    }

    #amenities{
        >main{
            flex-direction: column;
            >div{
                margin: .7rem;
            }
        }
    }

    #places{
        .swiper{
            gap: 1.5rem !important;
            .swiper-slide{
                width: 200px !important;
                height: 250px !important;
            }
        }
    }

    #contactus{
        padding: 2rem !important;
        >.wrapper form aside{
            flex-direction: column;
            gap: 0 !important;
        }
    }

    .footer{
        flex-direction: column;

        >.icondetail, .linkscontacts, .links, .contacts{
            width: 100% !important;
        }

        >.linkscontacts .links{
            align-items: flex-start !important;
            padding: 1.5rem 1rem !important;
        }
    }
}

@media screen and (max-width: 414px) {
    
    .navbaraside{
        width: 100% !important;

        >div{
            transform: translateX(0) !important;
        }
    }

    #contact{
        right: 19% !important;
    }

    #contactmodal{
        right: 20% !important;
    }

    .hero{
         section{
        main{
            h2{
                margin-top: 4rem;
            }
        }
    }
    }

    #aboutus{ 
        flex-direction: column;
        padding: 2rem !important;

        >.aboutImages{
            width: 100% !important;

            >p{
                align-items: flex-start !important;
                margin-top: 1rem !important;
                padding-bottom: 2rem;

                >.img1{
                    width: 90% !important;
                    height: 25rem !important;
                }

                >.img2{
                    margin-left: 5rem !important;
                    width: 80% !important;
                    height: 10rem !important;
                }
            }
        }
        >.aboutContent{
            padding: 1rem 2rem !important;
            border-radius: 10px;
        }
    }

    #gallery{
        .imgdiv{
            height: 150px !important;
            width: 200px !important;
            .imgimg{
                height: 150px !important;
                width: 200px !important;
            }
        } 
    }

    #places{
        padding-inline: 0 !important;
        .swiper{
            padding-left: 1rem;
            display: grid;
            gap: 1rem !important;
            grid-template-columns: repeat(2, 1fr) !important;
            .swiper-slide{
                width: 150px !important;
                height: 200px !important;
                .slidecontent{
                    padding: .7rem !important;
                }
            }
        }
    }
    
    #contactus{
        >.wrapper{
            display: flex !important;
            flex-direction: column;
        }
    }

    .footer .linkscontacts{
        flex-direction: column;

        .links{
            align-items: center !important;
        }

        >.contacts a p{
            font-size: 1rem !important;
        }
    }
}