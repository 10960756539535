@import "statics";

.footer{
    width: 100%;
    min-height: 10vh;
    background-color: $color_darkblue;
    // box-shadow: 0 5px 5px $color_platinum inset;
    display: flex;

    >.icondetail{
        width: 40%;
        background-color: rgba($color: $color_white_d, $alpha: 0.7);
        padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 3vw + 2rem, 5rem);
        display: flex;
        flex-direction: column;
        align-items: center;

        >img{
            width: 15vmax;
            height: 8vmax;
        }

        >p{
            text-align: justify;
            font-weight: 400;
            font-size: 0.9rem;
            font-family: $text4;
            line-height: 120%;
            color: $color_gray_d;
        }
    }

    >.linkscontacts{
        display: flex;
        width: 60%;

    >.links{
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color_gray_d;
        background-color: rgba($color: $color_white_d, $alpha: 0.7);
        padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 1vw + 1.2rem, 3rem);

        >h2{
            font-weight: 600;
            font-family: $text3;
            font-size: clamp(1rem, 1vw + 0.8rem, 2rem);
            margin-bottom: 0.5rem;
        }

        >a{
            color: $color_gray_d;
            font-weight: 400;
            font-size: 1.1rem;
            font-family: $text4;
        }
    }

    >.contacts{
        width: 60%;
        display: flex;
        flex-direction: column;
        color: $color_gray_d;
        background-color: rgba($color: $color_white_d, $alpha: 0.7);
        padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 1vw + 1.2rem, 3rem);

        >h2{
            font-weight: 600;
            font-family: $text3;
            font-size: clamp(1rem, 1vw + 0.8rem, 2rem);
            margin-bottom: 0.5rem;

            &:last-of-type{
                margin-top: clamp(1rem, 1vw + 0.8rem, 2rem);
            }
        }

        >a{
            color: $color_gray_d;
            display: flex;

            p{
                font-weight: 400;
                font-size: 1.2rem;
                font-family: $text4;
            }
            
            >.location {
                font-size:30px;
            }

            >svg{
                margin: 8px 10px 0 0;
            }
        }

        >div >a{
            color: $color_gray_d;

            >svg{
                margin: 8px 10px 0 0;
                font-size: 20px;
            }
        }
    }
}
}