@import "statics";

#amenities{
    width: 100%;
    min-height: 10vh;
    background-color: $color_yellow_dd;
    color: $color_white_d;
    padding-bottom: clamp(1.5rem, 1vw + 1.2rem, 3rem);

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        text-align: center;
        padding: clamp(1rem, 1vw + 0.8rem, 2rem);
    }

    >main{
        display: flex;
        justify-content: space-around;

        >.card{
            text-align: center;

            >svg{
                font-size: 3rem;
            }
        }
    }
}