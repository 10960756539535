@import "statics";

#places{
    min-height: 10vh;
    width: 100%;
    position: relative;
    padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 3vw + 2rem, 5rem) 0 clamp(1.5rem, 3vw + 2rem, 5rem);

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        text-align: center;
    }

    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 250px;
        height: 300px;
        border-radius: 20px;
        position: relative;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

        img {
            display: block;
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .slidecontent{
            position: absolute;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            bottom: 0;
            width: 100%;
            background-color: rgba($color: $color_black, $alpha: .5);
            padding: 1.5rem;
            color: $color_white;

            >div{
                display: flex;
                >svg{
                    margin: 4px 4px 0 0 ;
                }
            }
        }
      }
}

