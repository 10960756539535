@import "statics";

#contactus{
    width: 100%;
    padding: clamp(1.5rem, 3vw + 2rem, 5rem) clamp(6rem, 5vw + 6rem, 11rem);
    min-height: 10vh;
    // background-color: $color_white_d;

    >.wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    >form{
        width: 100%;
        grid-column: 1/4;
        min-height: 50vh;
        background-color: $color_white;
        padding: clamp(1.5rem, 1vw + 1.2rem, 3rem) clamp(1.5rem, 3vw + 2rem, 5rem);
        display: flex;
        flex-direction: column;

        >h2{
            font-weight: 700;
            font-family: $text5;
            text-align: center;
            font-size: clamp(1rem, 1vw + 0.8rem, 2rem);
            margin-bottom: clamp(1rem, 1vw + 0.8rem, 2rem);
        }

        >aside{
            display: flex;
            gap:1rem;

            >div{
                width: 100%;
                display: flex;
                flex-direction: column;

                >input, textarea{
                    outline: none;
                    border: none;
                    padding: .5rem;
                    margin-bottom: 1rem;
                    color: $color_gray_d;
                    border-radius: 7px;
                    border-bottom: 1px solid $color_gray;
                }
            }
        }

        >input, textarea{
            outline: none;
            border: none;
            padding: .5rem;
            margin-bottom: 1rem;
            color: $color_gray_d;
            border-radius: 7px;
            border-bottom: 1px solid $color_gray;
        }

        >button{
            background-color: $color_yellow_dd;
            border: none;
            padding: 0.5rem;
            color: $color_white;
            border-radius: 10px;
            margin-top: .5rem;
            cursor: pointer;
        }
        
        button:disabled, button[disabled]{
            cursor: not-allowed;
        }
    }

    >div{
        width: 100%;
        height: 100%;
        grid-column: 4/6;
    }

}
}

.formErrors{
    font-size: x-small;
    color: red;
    margin-top: -.8rem;
}