$text1: "Roboto", sans-serif;
$text2: "Open Sans", sans-serif;
$text3: "Lora", sans-serif;
$text4: 'Gentium Book Plus', serif;
$text5: 'Playfair Display', serif;

$color_white: #fff;
$color_white_d: #f5f5f5;
$color_beige: rgb(245, 245, 220);
$color_yellow_d: rgb(218, 165, 32);
$color_yellow_dd: #e3b04b;
$color_yellow_l: rgb(252, 245, 95);
$color_yellow: rgb(254, 220, 86);
$color_teal_d: rgb(0,31,32);
$color_teal: rgb(1,79,80);
$color_teal_l: rgb(1,127,128);
$color_blue_ll: rgb(204, 204, 255);
$color_black: rgb(0,0,0);
$color_black_l: #071330;
$color_red_d: #ee4758;
$color_darkblue:rgb(26, 30, 40);
$color_gray_l: rgb(229, 228, 226);
$color_gray: rgb(169,169,169);
$color_gray_d: rgb(56, 55, 55);
$color_platinum: rgb(245, 241, 232);
$color_lightblue_l: rgb(226,234,236);
$color_whatsapp: rgb(37 211 102);