@import "statics";

.hero{
    width: 100%;
    height: 100vh;

    >section{

    >.carousel{
        height: 100vh;
        width: 100%;

        .slide{
            img{
                height: 100vh !important;
                width: 100% !important;
                object-fit: cover;
            }
        }
    }

    >main{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: $color_black, $alpha: 0.7);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color_white_d;

        >h2{
            font-weight: 500;
            font-family: $text3;
            color: $color_yellow_dd;
            font-size: clamp(1.2rem, 1vw + 1.5rem, 2.5rem);
            padding: clamp(0.9rem, 1vw + 0.8rem, 2rem);
            text-align: center;
        }

        >p{
            font-weight: 300;
            font-family: $text3;
            font-size: clamp(0.9rem, 1vw + 0.8rem, 2rem);
            padding-bottom: 0.5rem;
        }

        >div {
            display: flex;
            justify-content: center;
            width: 100%;
            >a{
                color: $color_white;
                width: fit-content;
                padding: 0.7rem clamp(0.9rem, 1vw + 0.8rem, 2rem);
                border: 2px solid $color_white_d;
                margin: clamp(0.5rem, 1vw + 0.4rem, 2rem);
                font-weight: 700;
                font-family: $text2;
                font-size: clamp(0.9rem, 1vw + 0.5rem, 1.5rem);
                border-radius: 10px;
                backdrop-filter: blur(0.5rem);
                transition: all .3s;

                &:hover{
                    box-shadow: $color_white 2.4px 2.4px 3.2px;
                }
            }
        }
    }
}
}