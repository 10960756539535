@import "statics";

#gallery{
    // z-index: 100;

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        margin: 2rem auto;
        width: fit-content;
    }

    .imgdiv{
        overflow: hidden;
        height: 250px;
        margin-bottom: 2rem;

        .imgimg{
            width: 300px;
            height: 250px;
            object-fit: cover;
            cursor: pointer;
            transition: all 0.3s;

            &:hover{
                transform: scale(1.1);
            }
        }
    } 

    .modal{
        background-color: rgba($color: $color_black, $alpha: 0.6);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        transition: .25s ease-out;

        >.modalimg{
            position: absolute;
            height: 70%;
            top:50%;
            left: 50%;
            transform: scale(0.5) translate(-50%, -50%);
            transition: all 0.25s ease-out;
        }

        >.modalimgopen{
            position: absolute;
            height: 70%;
            top:50%;
            left: 50%;
            transform: scale(1) translate(-50%, -50%);
            transition: all 0.25s ease-out;
        }
    }

    .modalopen{
        background-color: rgba($color: $color_black, $alpha: 0.6);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        pointer-events: all;
        transition: .25s ease-out;
        z-index: 999;

        >.modalimg{
            position: absolute;
            height: 70%;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%)  scale(0.5);
            transition: all 0.25s ease-out;
        }

        >.modalimgopen{
            position: absolute;
            height: 70%;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            transition: all 0.25s ease-out;
        }
    }
}

.arrowleft, .arrowright{
    background-color: transparent;
    cursor: pointer;
    border: none;

    >svg{
        font-size: clamp(1.5rem, 3vw + 2rem, 4rem);
        color: $color_white;
    }
}

.arrowleft{
    position: absolute;
    left: 1.5rem;
}

.arrowright{
    position: absolute;
    right: 1.5rem;
}