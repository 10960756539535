@import "statics";

#aboutus{
    width:100%;
    display: flex;
    justify-content: center;
    padding: clamp(1.5rem, 3vw + 2rem, 5rem);
    background-color: $color_white_d;
    position: sticky;

    >.aboutImages{
        width: 70%;
        
        >p{
            position:sticky;
            top:10%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            >.img1{
                width: clamp(9rem, 10vw + 12rem, 20rem);
                height: clamp(14rem, 10vw + 17rem, 25rem);
                object-fit: cover;
                border-radius: 20px;
            }
    
            >.img2{
                margin-top: -4rem;
                margin-right: -4rem;
                width: clamp(9rem, 10vw + 9rem, 18rem);
                height: clamp(6rem, 7vw + 6rem, 11rem);
                object-fit: cover;
                border-radius: 20px;
            }
        }
    }

    >.aboutContent{
        width: 100%;
        background-color: $color_white;
        padding: 4rem 8rem;

        >h2{
            padding: 1rem 0;
            font-weight: 600;
            font-family: $text3;
            font-size: clamp(1rem, 1vw + 0.8rem, 2rem);
            text-align: center;
        }

        >p{
            text-align: justify;
            font-weight: 400;
            font-size: 1rem;
            font-family: $text4;
            color: $color_gray_d;
            letter-spacing: 2px;

            &:last-of-type{
                padding-bottom: 1rem;
            }
        }

        >ul >li {
            text-align: justify;
            font: 400 1rem $text4;
            color: $color_gray_d;
            letter-spacing: 2px;
        }
    }
}