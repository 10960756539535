@import "statics";

#services{
    width: 100%;
    min-height: 20vh;
    padding: clamp(1.5rem, 1vw + 1.2rem, 3rem);

    >h2{
        font-weight: 600;
        font-family: $text3;
        font-size: clamp(1.5rem, 1vw + 1.2rem, 3rem);
        text-align: center;
        padding-bottom: clamp(1rem, 1vw + 0.8rem, 2rem);
    }

    main{
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        text-align: center;

        >.card{
            width: 100%;
            padding: 1.5rem;
            position:relative;
            transition: 0.5s;

            &::before {
                width: 100%;
                height:100%;
                // z-index: 3;
                content:'';
                position: absolute;
                top:0;
                left:50%;
                box-sizing: border-box;
                transform: scaleX(0.2) translateX(-50%);
                transition: 0.5s;
            }

            &::before{
                border-bottom: 3px solid $color_yellow_dd;
                transform-origin: 0% 100%;
            }
            
            &:hover::before {
                transform: scale(1) translateX(-50%);
            }

            &:hover{
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            }

            >svg{
                font-size: 5rem;
                color: $color_yellow_dd;
                padding: 10px;
                border-bottom: 2px dotted $color_yellow_dd;
            }

            >h4{
                font-weight: 500;
                font-family: $text1;
                font-size: 1.5rem;
                padding: .8rem 0 .4rem 0;
            }

            >p{
                font-weight: 400;
                font-size: 1rem;
                font-family: $text4;
                letter-spacing: 1px;

                
            }
        }
    }
}