@import "statics";
@import "media";

.navBar{
    width: 100%;
    min-height: 1vh;
    // border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    position: fixed;
    background-color: rgba($color: $color_white_d, $alpha: 0.5);
    top: 0;
    z-index: 200;
    box-shadow: 1px 1px 10px $color_gray-d;

    >img{
        width: 6.5rem;
        height: 3.7rem;
        margin: auto 0;
        // position: absolute;
        // left: 47%;
        // transform: scale(130%);
        // top:10%;
    }

    >div{
        display: flex;
        align-items: center;
        gap:1rem;

        >a{
            color: $color_gray-d;

            >svg{
                font-size: 1.3rem;
            }
        }
    }

    >aside{
        color: $color_gray_d;
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        font-weight: 600;
        transition: all 0.5s;
    }

}

.navbaraside{
    width: 30%;
    height: 90vh;
    background-color: rgba($color: $color_white, $alpha: 0.5);
    backdrop-filter: blur(0.5rem);
    position: fixed;
    top: 10%;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    box-shadow: 1px 1px 10px $color_gray-d;
    
    >main{
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        font: 400 1.1rem $text1;
        margin-left: 2rem;
        width: 80%;

        >a{
            color: $color_black;
            width: 100%;
            position: relative;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
            padding-bottom: 0.7rem;
        }
    }

    >div{
        display: flex;
        gap: 0.5rem;
        font-size: 1.5rem;
        margin: 0 auto;

        >a{
            color: $color_black;

            &:hover{
                transform: scale(1.1);
            }
        }
        transform: translateX(300%);
    }
}